<template>
  <div>
    <div
      class="text-caption mt-n1 mb-1"
      v-if="search.queryDetails.minimalMatchPercentage >= 0"
    >
      <v-icon
        :color="search.sendUpdatesPerEmail.daily
          || search.sendUpdatesPerEmail.realTime
          || search.sendUpdatesPerEmail.weekly ? 'teal lighten-1' : 'grey lighten-1'"
      >
        mdi-email
      </v-icon>
      <span v-if="showPercentage">
        {{ search.queryDetails.minimalMatchPercentage }}%
      </span>

    </div>
    <div
      class="custom-search-title text-body-2 font-weight-medium"
      v-if="search.title"
      style="
          max-height: 48px;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        "
    >
      {{ search.title }}
    </div>
    <div
      class="text-body-2 mt-2 text-truncate"
      v-if="search.queryDetails.query"
    >
      {{ search.queryDetails.query }}
    </div>
    <div
      class="text-caption mt-2"
      v-if="search.queryDetails.context.length > 0"
    >
      {{ this.searchContext() }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import mapNewMinistryCodes from '@/helpers/contextFilterTranslator';

export default {
  name: 'DocumentSavedSearchesItemDetailSaved',

  props: {
    search: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      filters: 'userFilters',
    }),

    showPercentage() {
      if (this.search.queryDetails.context.length
        === this.filters.context.length) return false;
      return true;
    },
  },

  methods: {
    searchContext() {
      return this.search.queryDetails.context.map((context) => mapNewMinistryCodes(context)).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1904px) {
  .custom-search-title {
    font-size: 13.6px !important;
  }
}
</style>
