import { render, staticRenderFns } from "./EuSavedSearchItemDetail.vue?vue&type=template&id=5c857555&scoped=true&"
import script from "./EuSavedSearchItemDetail.vue?vue&type=script&setup=true&lang=js&"
export * from "./EuSavedSearchItemDetail.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./EuSavedSearchItemDetail.vue?vue&type=style&index=0&id=5c857555&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c857555",
  null
  
)

export default component.exports