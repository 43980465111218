import { computed } from 'vue';
import { useStore } from '@/helpers/storeHelper';
import moment from 'moment';
import { getGroupLocalization } from './services/grouping_service';

export default function useEuSavedSearches(props) {
  const store = useStore();

  const selectedFiltersShorthand = computed(() => {
    const { euGrouping } = store.getters.userFilters;
    if (euGrouping) {
      const { groupPaths, euEpCommittees } = props.search.queryDetails;
      const result = [];

      const addShorthandForGroupCode = (groupCode) => {
        const group = euGrouping.groups[groupCode];
        if (!group) return;
        const localization = getGroupLocalization(group, { type: 'short', locale: moment.locale() });
        const paths = groupPaths.filter((path) => path.startsWith(groupCode));
        const rootNodeSelected = (paths.length === 1 && paths[0] === groupCode);
        let length;
        if (rootNodeSelected) {
          const { subGroups } = euGrouping.groups[groupCode];
          if (subGroups) ({ length } = Object.keys(subGroups));
        } else {
          ({ length } = paths);
        }
        if (length !== 0) result.push(`${localization}${length ? ` (${length})` : ''}`);
      };

      addShorthandForGroupCode('EC');
      addShorthandForGroupCode('EP-PL');
      addShorthandForGroupCode('EP-COM-OVERIG');
      addShorthandForGroupCode('EU-RAAD');
      addShorthandForGroupCode('EU-SH');
      addShorthandForGroupCode('EU-MD');
      // EP-COM - Based on committees instead of the docTypes!
      const euEpComGroup = euGrouping.groups['EP-COM'];
      if (euEpComGroup) {
        const euEpComLocalization = getGroupLocalization(euEpComGroup, { type: 'short', locale: moment.locale() });
        if (euEpCommittees.length > 0) result.push(`${euEpComLocalization} (${euEpCommittees.length})`);
      }

      return result.join(', ');
    }
    return undefined;
  });

  return {
    selectedFiltersShorthand,
  };
}
