<template>
  <div>
      <div
        class="text-caption mt-n1 mb-1"
        v-if="search.queryDetails"
      >
        <v-icon
          :color="search.sendUpdatesPerEmail.daily
            || search.sendUpdatesPerEmail.realTime
            || search.sendUpdatesPerEmail.weekly ? 'teal lighten-1' : 'grey lighten-1'"
        >
          mdi-email
        </v-icon>
      </div>
      <div
        class="custom-search-title text-body-2 font-weight-medium"
        v-if="search.title"
        style="
          max-height: 48px;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        "
      >
        {{ search.title }}
      </div>
      <div
        class="text-body-2 mt-2 text-truncate"
        v-if="search.queryDetails.query"
      >
        {{ search.queryDetails.query }}
      </div>
      <div
        class="text-caption mt-2"
        style="font-size: 80% !important;"
      >
        {{ selectedGroupPaths }}
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'nl-lr-saved-searches-item-detail-saved',

  props: {
    search: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      municipalitiesCounter: 0,
      provincesCounter: 0,
    };
  },

  computed: {
    ...mapGetters({
      userFilters: 'userFilters',
    }),

    selectedGroupPaths() {
      const { nlLrGrouping } = this.userFilters;
      if (nlLrGrouping) {
        const { queryDetails: { groupPaths = {} } } = this.search;
        const result = [];
        if (groupPaths) {
          if (groupPaths.length === 0) {
            if (nlLrGrouping.groups.MUN) result.push(`Gemeenten(${Object.keys(nlLrGrouping.groups.MUN.subGroups).length})`);
            if (nlLrGrouping.groups.PR) result.push(`Provincies(${Object.keys(nlLrGrouping.groups.PR.subGroups).length})`);
          }
          groupPaths.forEach((item) => {
            if (item === 'MUN' && nlLrGrouping.groups.MUN) this.municipalitiesCounter = Object.keys(nlLrGrouping.groups.MUN.subGroups).length;
            if (item !== 'MUN' && item.startsWith('MUN')) this.municipalitiesCounter += 1;
            if (item === 'PR' && nlLrGrouping.groups.PR) this.provincesCounter = Object.keys(nlLrGrouping.groups.PR.subGroups).length;
            if (item !== 'PR' && item.startsWith('PR')) this.provincesCounter += 1;
          });
          if (this.municipalitiesCounter > 0) {
            result.push(`Gemeenten(${this.municipalitiesCounter})`);
          }
          if (this.provincesCounter > 0) {
            result.push(`Provincies(${this.provincesCounter})`);
          }
        }
        this.resetCouters();
        return result.toString().replace(',', ', ');
      }
      return undefined;
    },
  },

  methods: {
    initSelectedGroupPaths() {
      return this.selectedGroupPaths;
    },

    resetCouters() {
      this.municipalitiesCounter = 0;
      this.provincesCounter = 0;
      return null;
    },
  },

  mounted() {
    this.initSelectedGroupPaths();
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1904px) {
  .custom-search-title {
    font-size: 13.6px !important;
  }
}
</style>
