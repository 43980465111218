// This function exists to give contexts shown in the saved search card appropriate abbreviations that match the renamed
// filters. See https://project-w.atlassian.net/browse/POLMONNL-5854 for more details.
// TODO: this function will not be needed once we do a proper migration of the contexts, remove this when that happens

const mapNewMinistryCodes = (oldCode) => {
  switch (oldCode) {
    case 'BZ':
      return 'BZK&VRO';
    case 'LNV':
      return 'LVVN';
    case 'EZK':
      return 'EZ&KGG';
    case 'JenV':
      return 'JV&AM';
    default:
      return oldCode;
  }
};

export default mapNewMinistryCodes;
