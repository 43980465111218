<!--
  Textbar and search button used to search for a certain subject
-->

<template>
  <v-text-field
    filled
    dense
    flat
    color="#0c567a"
    v-model="query"
    :value="query"
    autocomplete="off"
    prepend-icon="mdi-help-circle"
    append-icon="mdi-magnify"
    :maxlength="queryLength"
    @click:prepend="supportDialogVisible()"
    @click:append="searchButtonPress()"
    @keyup.enter.native="searchKeyPress"
    :placeholder="placeholder"
    class="pt-7 pr-md-14"
    style="width: 640px"
    outlined
    solo
  />
</template>

<script>
export default {
  name: 'SearchBar',

  props: {
    onSearchAction: {
      type: String,
      required: true,
    },
    setQueryAction: {
      type: String,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    queryLength: {
      type: Number,
      required: true,
    },
  },

  methods: {
    updateUrlParameters() {
      this.$router
        .push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ...{ query: this.query },
          },
        })
        .catch(() => {});
    },
    searchButtonPress() {
      this.$gtag.event('search-via-button', {
        event_category: 'search',
      });
      this.search();
    },
    searchKeyPress() {
      this.$gtag.event('search-via-keypress', {
        event_category: 'search',
      });
      this.search();
    },
    search() {
      this.$store.dispatch(this.onSearchAction);
      this.updateUrlParameters();
    },
    getAutocomplete(queryString, cb) {
      this.$store.dispatch('getAutocompleteByQuery', queryString);
      cb(this.$store.getters.getAutocompleteQuery);
    },
    updateQuery(autocompleteObject) {
      this.query = autocompleteObject.value;
      this.search();
    },
    onSubmit() {
      return false;
    },

    supportDialogVisible() {
      this.$gtag.pageview({
        page_path: '/search/help',
      });
      this.$store.dispatch('setSupportDialogVisible', true);
    },
  },

  computed: {
    query: {
      get() {
        return this.$store.getters[this.searchQuery];
      },
      set(query) {
        this.$store.dispatch(this.setQueryAction, query);
      },
    },
  },

  mounted() {
    // Empty searchbar initially
    this.query = '';
  },

  watch: {
    query(val) {
      // POLMONNL-2242 QueryLength is used to limit the max length of the query
      // This is a prop that is set in the parent object
      if (val && val.length >= this.queryLength) {
        this.$store.dispatch('setMessage', {
          message: `${this.$t('generic.maximum')} ${this.queryLength} ${this.$t('generic.characters')}`,
          type: 'info',
        });
      }
    },
  },

};
</script>

<style scoped lang="scss">
</style>
