<template>
  <div>
    <div
      class="text-caption mt-n1 mb-1"
      v-if="
        search.queryDetails && search.queryDetails.minimalMatchPercentage
      "
    >
      <v-icon color="grey lighten-1">
        mdi-email
      </v-icon>
      {{ search.queryDetails.minimalMatchPercentage }}%
    </div>
    <div
      class="text-body-2 mt-2"
      v-if="search.queryDetails && search.queryDetails.query"
      style="
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      "
    >
      {{ search.queryDetails.query }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgendaSavedSearchesItemDetailRecent',

  props: {
    search: {
      type: Object,
      required: true,
    },
  },
};
</script>
