<template>
  <div>
    <div class="line" :class="{ 'wrap-enabled': wrap }">
      <v-btn
          :class="(!item.children ? ' hidden ' : '')"
          icon
          @click="toggleShowChildren()"
          v-if="!openAll"
      >
        <v-icon :class="showChildren ? 'open' : 'closed'">
          mdi-menu-down
        </v-icon>
      </v-btn>

      <label
          class="checkboxLabel"
      >
        <!-- eslint-disable vue/no-mutating-props -->
        <input
            type="checkbox"
            v-model="item.checkboxValue"
            @change="checkboxChanged"
        >
        <!-- eslint-enable vue/no-mutating-props -->
        <span class="checkboxVisual">
          <v-icon
              :color="item.checkboxValue ? 'teal lighten-1' : ''"
          >
            {{ (item.checkboxValue === 'indeterminate') ? 'mdi-minus-box': (item.checkboxValue ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline') }}
          </v-icon>
        </span>
        <span
            class="labelText"
            :class="{ 'wrap-enabled': wrap }"
            :title="itemText ? item[itemText] : item.name"
        >
          {{ itemText ? item[itemText] : item.name }}
        </span>
      </label>
      <!-- Filter explanation -->
      <v-btn
          v-if="item.explanationFunction"
          icon
          x-small
          @click="item.explanationFunction()"
      >
        <v-icon small>mdi-help-circle</v-icon>
      </v-btn>
    </div>
    <div
        v-if="(openAll || showChildren) && item.children"
    >
      <ul class="pl-5">
        <li v-for="child in item.children" :key="child[itemKey]">
          <treeview-item
              :item="child"
              :openItems="openItems"
              :treePath="treePath.concat([child[itemKey]])"
              @checkbox-changed="childCheckboxChanged"
              :itemKey="itemKey"
              :selectedColor="selectedColor"
              :openAll="openAll"
              :compact="compact"
              :itemText="itemText"
              :wrap="wrap"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'treeview-item',

  emit: ['checkboxChanged'],

  props: {
    item: Object,
    openItems: Array,
    treePath: Array,
    itemKey: String,
    itemText: String,
    openAll: Boolean,
    selectedColor: String,
    compact: Boolean,
    wrap: Boolean,
  },

  data() {
    return {
      showChildren: this.openItems ? this.openItems.includes(this.item[this.itemKey]) : true,
    };
  },

  methods: {
    toggleShowChildren() {
      this.showChildren = !this.showChildren;
    },
    checkboxChanged() {
      this.$emit('checkbox-changed', {
        changedItem: this.item,
        parents: [],
      });
    },
    childCheckboxChanged(eventData) {
      // Re-emit to own parent
      eventData.parents.push(this.item);
      this.$emit('checkbox-changed', eventData);
    },
  },
};
</script>

<style lang="scss" scoped>
.mb-2px { // Hacker-de-hack
  padding-bottom: 2px;
}

ul {
  li {
    div.line {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      font-size: 14px;

      &.wrap-enabled {
        margin-bottom: 5px;
      }

      .v-btn {
        width: 24px;
        height: 24px;

        .v-icon.closed {
          transform: rotate(-90deg);
        }

        &.hidden {
          visibility: hidden;
        }
      }

      .checkboxLabel {
        cursor: pointer;
        display: flex;
        min-width: 0; // required for the `text-overflow: ellipsis;` to work;

        line-height: 1.2;
        position: relative;

        .labelText {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-top: 0.4em;

          &.wrap-enabled {
            overflow: visible;
            white-space: normal;
            text-overflow: ellipsis;
          }
        }

        .checkboxVisual {
          position: relative;
          flex-shrink: 0;
          margin-right: 0.4em;
        }

        input:focus + .checkboxVisual .v-icon::after,
        .checkboxVisual:hover .v-icon::after {
          opacity: 0.2;
          width: 130%;
          height: 130%;
          left: -15%;
          top: - 15%;
        }

        input {
          opacity: 0;

          position: absolute;
          left: 0;
          top: 0;

          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
